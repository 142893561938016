import moment from "moment";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import packageIcon from "../../Assets/Images/ic-packageIcon.png";
import parkingIcon from "../../Assets/Images/ic-parking.png";
import ticketIcon from "../../Assets/Images/ic-ticket.png";
import "./featuredCard.scss";

export default function FeatureCard(props: any) {
  const { data } = props;
  const navigate = useNavigate();
  const onNavigate = (id: any, slug: any) => {
    navigate(`/Venue/VenueCardDetails/${slug}`, { state: { id: id } });
  };

  return (
    <>
      <div className="col-md-4 col-lg-3">
        <div className="FeatCard">
          <div className="FeaturedCardSection">
            <div className="XmaxCard">
              <div className=" XmasImage">
                <div className="ShowDate">
                  <p>{data && moment(data.startDateTime).format("MMM")}</p>
                  <p>{data && moment(data.startDateTime).format("DD")}</p>
                </div>

                <Link to={`/EventChild/${data.slug}`}>
                  <img src={data.featureImage} alt="" />
                </Link>
              </div>
              <div className=" XmasDetails XmasMin">
                <div className="EveHeading">
                  <h3> {data.title}</h3>
                </div>
                <div className="PlaceName">
                  <p>{data.subTitle}</p>
                  <div className="SubContent">

                  <a href={data.ticketUpgradeLink} target="_blank">
                    {data.isTicketUpgrade == true ? (
                      <button type="button">
                        <img src={ticketIcon} alt="" /> TICKET UPGRADE
                      </button>
                    ) : (
                      ""
                    )}
                  </a>
                  <a href={data.parkingLink} target="_blank">
                    {data.isParking == true ? (
                      <button type="button">
                        <img src={parkingIcon} alt="" /> PARKING
                      </button>
                    ) : (
                      ""
                    )}
                  </a>
                  <Link to={`/EventChild/${data.slug}`}>
                    {data.isPackage == true ? (
                      <button type="button">
                        <img src={packageIcon} alt="" /> PACKAGE
                      </button>
                    ) : (
                      ""
                    )}
                  </Link>
                  </div>
                </div>
                <div className="PartyHeading PartyTime">
                  <p> Show {moment(data.startDateTime).format("LT")} (ET)</p>
                  <p>
                    <a>
                      <div
                        className="companys"
                        onClick={() =>
                          onNavigate(data.venue._id, data.venue.slug)
                        }
                      >
                        <p>{data.venue.title}</p>
                      </div>
                    </a>
                  </p>
                </div>
                <div className="cardFooter">
                  <div className="PriceSec">
                    {data?.tickets?.length > 0 ? (
                      <>
                        <button type="button" className="Price">
                          {data.price}
                        </button>
                        <button type="button" className="SaleHeading">
                          {data?.isOnsale ? (
                            <a href={data?.generalTicketLink} target="_blank">
                              Buy Now
                            </a>
                          ) : (
                            <a>
                              {`ON SALE ${moment(data?.onSaleDateTime).format(
                                "MM/DD"
                              )}`}
                            </a>
                          )}
                        </button>
                      </>
                    ) : (
                      <button type="button" className="SaleHeading">
                        <span>TBA</span>
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
