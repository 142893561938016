import React from "react";
import { Link, useNavigate } from "react-router-dom";
import "./secondCard.scss";
import moment from "moment";
import packageIcon from "../../Assets/Images/ic-packageIcon.png";
import parkingIcon from "../../Assets/Images/ic-parking.png";
import ticketIcon from "../../Assets/Images/ic-ticket.png";

export default function SecondCard(props: any) {
  const item = props.item;
  const navigate = useNavigate();
  const onNavigate = (id: any, slug: any) => {
    navigate(`/Venue/VenueCardDetails/${slug}`, { state: { id: id } });
  };

  return (
    <div className="SecondCardSection">
      <div className="container">
        <div className="row">
          <div className="SecondCardContent">
            <div className="SeconCardDate">
              <div className="date">
                {item && moment(item.startDateTime).format("MMM")}
                <br />
                {item && moment(item.startDateTime).format("DD")}
              </div>
            </div>
            <div className="SecondCardTitle">
              <Link to={`/EventChild/${item?.slug}`}>
                <div className="RightDiv">
                  <h3>{item.title}</h3>
                  <p>{item.subTitle}</p>
                </div>
              </Link>
              <div className="LeftDiv">
                <div className="SubContent">
                  
                    <Link to={`/EventChild/${item.slug}`}>
                      {item?.isPackage == true ? (
                        <button type="button" className="parkingBtn">
                          <img src={packageIcon} alt="" />
                          <span className="PackageParking">PACKAGE</span>
                        </button>
                      ) : (
                        ""
                      )}
                    </Link>
                 
                  <a href={item?.parkingLink} target="_blank">
                    {item?.isParking == true ? (
                      <button type="button" className="parkingBtn">
                        <img src={ticketIcon} alt="" />
                        <span className="PackageParking">PARKING</span>
                      </button>
                    ) : (
                      ""
                    )}
                  </a>
                  <a href={item?.ticketUpgradeLink} target="_blank">
                    {item?.isTicketUpgrade == true ? (
                      <button type="button" className="parkingBtn">
                        <img src={parkingIcon} alt="" />
                        <span className="PackageParking">TICKET UPGRADE</span>
                      </button>
                    ) : (
                      ""
                    )}
                  </a>
                </div>
                <a onClick={() => onNavigate(item.venue._id, item.venue.slug)}>
                  {item.venue.title}
                </a>
              </div>
            </div>
            <div className="SecondCardBuy mobileViewHide">
              {item?.tickets?.length > 0 ? (
                <>
                  <div className="PriceBtn">
                    <button type="button" className="Price">
                      {item.price}
                    </button>
                  </div>
                  <div className="tbaBtn">
                    <button type="button" className="SaleHeading">
                      {item?.isOnsale ? (
                        <a href={item?.generalTicketLink} target="_blank">
                          Buy Now
                        </a>
                      ) : (
                        <a>
                          {`ON SALE ${moment(item?.onSaleDateTime).format("MM/DD")}`}
                        </a>
                      )}
                    </button>
                  </div>
                </>
              ) : (
                <div className="tbaBtn beforeOnSalebtn">
                  <button type="button" className="SaleHeading">
                    TBA
                  </button>
                </div>
              )}
            </div>
          </div>
          <div className="SecondCardBuy HideOnWeb">
            {item?.tickets?.length > 0 ? (
              <>
                <div className="PriceBtn">
                  <button type="button" className="Price">
                    {item.price}
                  </button>
                </div>
                <div className="tbaBtn">
                  <button type="button" className="SaleHeading">
                    {item?.isOnsale ? (
                      <a href={item?.generalTicketLink} target="_blank">
                        Buy Now
                      </a>
                    ) : (
                      <a>
                        {`ON SALE ${moment(item?.onSaleDateTime).format("MM/DD")}`}
                      </a>
                    )}
                  </button>
                </div>
              </>
            ) : (
              <div className="tbaBtn">
                <button type="button" className="SaleHeading">
                  TBA
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
