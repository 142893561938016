import React from "react";
import moment from "moment";
import "../CardSec/card.scss";
import { Link, useNavigate } from "react-router-dom";
import packageIcon from "../../Assets/Images/ic-packageIcon.png";
import parkingIcon from "../../Assets/Images/ic-parking.png";
import ticketIcon from "../../Assets/Images/ic-ticket.png";

export default function Card(props: any) {
  const events = props.events;
  const navigate = useNavigate();
  const onNavigate = (id: any, slug: any) => {
    navigate(`/Venue/VenueCardDetails/${slug}`, { state: { id: id } });
  };

  return (
    <>
      <div className={`col-sm-6 col-md-6 col-lg-6 FirstCard `}>
        <div className="CardSection">
          <div className="XmaxCard">
            <div className=" XmasImage">
              <div className="ShowDate">
                <p> {events && moment(events?.startDateTime).format("MMM")}</p>
                <p> {events && moment(events?.startDateTime).format("DD")}</p>
              </div>
              <Link to={`/EventChild/${events?.slug}`}>
                <img src={events?.featureImage || events?.bannerImage} alt="" />
              </Link>
            </div>
            <div className=" XmasDetails">
              <div className="EveHeading">
                <Link to={`/EventChild/${events?.slug}`}>
                  <h5>{events?.title}</h5>
                </Link>
              </div>
              <div className="PartyHeading partyContent">
                <p className="CardSub-title">{events?.subTitle}</p>
                <div className="SubContent">
                  <a href={events?.ticketUpgradeLink} target="_blank">
                    {events?.isTicketUpgrade == true ? (
                      <button type="button">
                        <img src={ticketIcon} alt="" />
                        <span className="PackageParking">TICKET UPGRADE</span>
                      </button>
                    ) : (
                      ""
                    )}
                  </a>
                  <a href={events?.parkingLink} target="_blank">
                    {events?.isParking == true ? (
                      <button type="button">
                        <img src={parkingIcon} alt="" />
                        <span className="PackageParking">PARKING</span>
                      </button>
                    ) : (
                      ""
                    )}
                  </a>
                  <Link to={`/EventChild/${events.slug}`}>
                    {events?.isPackage == true ? (
                      <button type="button">
                        <img src={packageIcon} alt="" />
                        <span className="PackageParking">PACKAGE</span>
                      </button>
                    ) : (
                      ""
                    )}
                  </Link>
                </div>

                <a
                  className="parkingAnchor"
                  onClick={() =>
                    onNavigate(events?.venue?._id, events?.venue.slug)
                  }
                >
                  <p className="SubPara">{events?.venue?.title}</p>
                </a>
              </div>
              <div className="cardFooter">
                {events?.tickets?.length > 0 ? (
                  <div className="PriceSec">
                    <button type="button" className="Price">
                      {events?.price}
                    </button>
                    <button type="button" className="SaleHeading">
                      {events?.isOnsale ? (
                        <a href={events?.generalTicketLink} target="_blank">
                          Buy Now
                        </a>
                      ) : (
                        <a>
                          {`ON SALE ${moment(events?.onSaleDateTime).format("MM/DD")}`}
                        </a>
                      )}
                    </button>
                  </div>
                ) : (
                  <div className="PriceSec">
                    {" "}
                    <button type="button" className="SaleHeading">
                      TBA
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
